import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { SimpleContentPage } from '@mangoart/gatsby-ui/components/ezagrar/SimpleContentPage';
import DefaultLayout from '../layout/DefaultLayout';

const NotFoundPage = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  // const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      {/* <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <SimpleContentPage {...content} /> */}
    </DefaultLayout>
  );
};

export default NotFoundPage;

export const NotFoundPageQuery = graphql`
  query NotFoundPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
  }
`;
